import React from "react";
import "normalize.css";
import "./index.scss";
import Header from "../components/Header/Header";
import GoldCircle from "../components/GoldCircle/GoldCircle";
import Form from "../components/Form/Form";

const IndexPage = () => {
  return (
    <>
      <Header />
      <main>
        <div className="main-wrap">
          <GoldCircle width="350px" borderWidth="10px">
            <div className="col">
              <div className="name">Sandi Bitenc</div>
              <div className="and">&</div>
              <div className="name">Petra Kmetec</div>
            </div>
          </GoldCircle>
          <h1>Vabilo na poroko</h1>
          <div className="yt">
            <iframe
              src="https://www.youtube.com/embed/VhRCblH3_ts?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              rel="0"
              listType="playlist"
              enablejsapi="1"
              modestbranding="1"
              showinfo="0"
            ></iframe>
          </div>
          <div className="and dateand">SOBOTA 15:30h</div>
          <div className="date">
            <div className="date1">9</div>
            <div className="date2">JULIJ</div>
            <div className="date3">2022</div>
          </div>
          <h3>Hiša vina Dopler</h3>

          <a
            href="https://goo.gl/maps/xYcdZLmSyyLSZ6Dp9"
            target="_blank"
            rel="noreferrer"
          >
            Kozjak nad Pesnico 79, 2201 Zgornja Kungota
          </a>

          <p>
            9.7 je za naju prav poseben dan. Sedem let nazaj sva ravno na ta
            datum začela najino skupno pot in sedaj jo želiva še nadgraditi. V
            vaši družbi seveda.
          </p>
          <p>
            Z vami bi rada delila najino srečo ter nadaljevanje čudovite zgodbe,
            ki jo toliko let piševa skupaj.
          </p>
          <p>
            Vabiva vaju na poroko, ki bo 9.7.2022 na prelepem vinotoču Dopler.
          </p>
          <p>
            Najina želja je, da se na poroki čimbolj sprostimo, uživamo in smo
            brez skrbi. Otroci bodo prišli drugič na vrsto, tokrat se družimo
            samo odrasli.
          </p>
          <p>
            Vem, da nama boste želeli podariti kakšno darilo. Najin dom je
            daleč, daleč proč, nekje za sedmimi gorami in sedmimi vodami, zato
            vas prosiva, da nama ne nosite ničesar fizičnega.
          </p>
          <p>
            Ne bo cerkvene poroke, lahko pa nama pustite ofer za poročno
            potovanje ;)
          </p>
          <p>
            Prosiva, da potrdite vajino prisotnost najkasneje do 10.5.2022 tako,
            da vpišete imena in priimke ter morebitne prehranske posebnosti v
            spodnje okence in kliknete na gumb <i>Potrjujem prihod</i>.
          </p>
          <Form />
        </div>
      </main>
    </>
  );
};

export default IndexPage;
